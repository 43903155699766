<template>
  <PostOverlay :Post="state.post" :Display="state.overlay" @close="closePreview"/>
  <div class="ctn">
    <FeedLeft />
    <div class="_01">
      <div class="_main">
        <div class="_xxd">
          <StoriesView/>
          <PostView 
            v-for="(post,index) in state.posts"
            :key="post.id"
            :post="post"
            :indx="index"
            @showComment="showComment"
            @previewPost="previewPost"
            ref="post"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedLeft from '@/components/FeedLeft.vue'
import StoriesView from '@/components/StoriesView.vue'
import PostView from '@/components/PostView.vue'
import PostOverlay from '@/components/PostOverlay.vue'
import { onMounted, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'FeedView',
  components: {
    FeedLeft,
    StoriesView,
    PostView,
    PostOverlay
  },
  setup(){
    const state=reactive({posts:[],overlay:"none",
      post:{
        id:"",
        dp:"",
        name:"",
        date:"",
        post:"",
        type:"txt",
        files:[""],
        isLike:true,
        likes:"23",
        comments:"10 comments"
      }
    });
    const post=new ref([])
    const store=useStore();
    const user = computed(() => store.state.User.user);

    function createNode(a){let b=document.createElement("div");b.innerHTML=a;return b.firstChild;}

    function showComment(id,p){
      p=p.value;
      const e=post.value[id].$refs.comment;
      const parent=e.parentNode.parentNode;
      // console.log(parent.querySelector(".nw-cmt"))
      if(parent.querySelector(".nw-cmt") !== null){
        parent.querySelector(".nw-cmt .p-cmt").focus();
        return;
      }
      var dp=user.value.dp;
      var cmm="<div class='nw-cmt float-wrap' role=''>"+
              "<div class='a-img'>"+
                  "<img src='"+dp+"'/>"+
              "</div>"+
              "<div class='p-cmt-wrap' role='"+p.id+"'>"+
                  "<p class='p-cmt' contenteditable='true'></p>"+
              "</div>"+
          "</div>";
      parent.append(createNode(cmm))
      const ele=parent.querySelector(".p-cmt")
      ele.setAttribute("value","");
      
      const placeholder = "Alright, Let's hear it!";
      
      ele.innerHTML === '' && (ele.innerHTML = placeholder);             
      ele.addEventListener('focus', function (e) {
          const value = e.target.innerHTML;
          value === placeholder && (e.target.innerHTML = '');
      });             
      ele.addEventListener('blur', function (e) {
          const value = e.target.innerHTML;
          value === '' && (e.target.innerHTML = placeholder);
          e.target.setAttribute("value",value);
      });

      ele.addEventListener("keypress", function(event) {
          if (event.key === "Enter") {
              event.preventDefault();
              ele.innerHTML !== '' && (new pstcm(ele))
          }
      });
    }

    function pstcm(e){
      this.p=e.parentNode.parentNode.parentNode;
      var revs=this.p.querySelector(".tm-pt-tt > .pst-smr > .cmm > span");
      this.p_id=e.parentNode.getAttribute("role");
      this.dp=user.value.dp;
      this.cmm=e.innerHTML;
        this.nw_cmt="<div class='u-cmt-row float-wrap' style='opacity:0.5'>"+
            "<div class='a-img'>"+
                "<img src='"+this.dp+"'/>"+
            "</div>"+
            "<div class='u-cmt-wrap'>"+
                "<p class='u-cmt'>"+this.cmm+
                "</p>"+
            "</div>"+
        "</div>";
        this.cm=createNode("<div></div>");
        var cm=this.cm,
        cmm=this.cmm;
        this.cm.append(createNode(this.nw_cmt));
        this.p.querySelector("#cmts").append(this.cm);
        e.innerHTML="";
        setTimeout(() => {
          var nw_cm="<div class='u-cmt-row float-wrap'>"+
                        "<div class='a-img'>"+
                            "<img src='"+this.dp+"'/>"+
                        "</div>"+
                        "<div class='u-cmt-wrap'>"+
                            "<p class='u-cmt'>"+cmm+
                            "</p>"+
                            "<div class='u-dtl float-wrap'>"+
                                "<span style='float:left'>now</span>"+
                            "</div>"+
                        "</div>"+
                    "</div>";
            cm.innerHTML="";
            cm.append(createNode(nw_cm))
            revs.innerHTML="22 Reviews";
        }, 2000);
        // _.ajax({
        //     method:"POST",
        //     url:"client/controllers/postController",
        //     data:{
        //         "action":"PostReview",
        //         "role":this.p_id,
        //         "review":this.cmm
        //     }, 
        //     success:function(e){
        //         if(e.status){
        //             var nw_cm="<div class='u-cmt-row float-wrap'>"+
        //                         "<div class='a-img'>"+
        //                             "<img src='"+this.dp+"'/>"+
        //                         "</div>"+
        //                         "<div class='u-cmt-wrap'>"+
        //                             "<p class='u-cmt'>"+cmm+
        //                             "</p>"+
        //                             "<div class='u-dtl float-wrap'>"+
        //                                 "<span v-time='"+e.msg.time+"' style='float:left'>now</span>"+
        //                             "</div>"+
        //                         "</div>"+
        //                     "</div>";
        //             _(cm).html("");
        //             _(cm).append(nw_cm)
        //             revs.html(e.msg.reviews);
        //         }else{

        //         }
        //     }, 
        //     dataType:"json"
        // })
    }

    function closePreview(){
      state.overlay="none"
    }

    function previewPost(index,post){
      console.log(index," ",post)
      state.post=post
      state.overlay="block"
    }
    
    onMounted(()=>{
      state.posts=[
        {
          id:"1",
          dp:"https://photo.candorjab.com/img?img=dp1.jpg",
          name:"Sha Kur",
          date:"Apr 7, 2023 by 3:29pm",
          post:"girl's Una sabi sleep oo 😅🤣",
          type:"img",
          files:["https://photo.candorjab.com/img?img=p1.jpg"],
          isLike:true,
          likes:"23",
          comments:"10 comments"
        },
        {
          id:"2",
          dp:"https://photo.candorjab.com/img?img=dp.png",
          name:"Blessed Beyond Measures",
          date:"Apr 7, 2023 by 3:19pm",
          post:"Good evening everyone, hope we are doing very well this wonderful day",
          type:"img",
          files:["https://photo.candorjab.com/img?img=p2.jpg","https://photo.candorjab.com/img?img=p3.jpg"],
          isLike:false,
          likes:"12",
          comments:"7 comments"
        },
        {
          id:"3",
          dp:"https://photo.candorjab.com/img?img=dp1.jpg",
          name:"Blessed Beyond Measures",
          date:"Apr 7, 2023 by 3:19pm",
          post:"My Video",
          type:"vid",
          files:["https://photo.candorjab.com/stream?vid=video3.mp4"],
          isLike:false,
          likes:"12",
          comments:"7 comments"
        }
      ]
    })

    return{
      state,
      showComment,
      post,
      createNode,
      pstcm,previewPost,closePreview,
      user
    }
    
    
  }
}
</script>
<style scoped>
nw-cmt {border-radius: 30px; margin: 10px 0 20px; display: flex;}
.nw-cmt .a-img {width: 40px; height: 40px;}
.nw-cmt .p-cmt-wrap {border: 1px solid #ccc; width: calc(100% - 50px); margin-left: 10px; border-radius: 20px;}
.nw-cmt .p-cmt {padding: 10px; margin: 0; outline: none; border: 0; max-height: 50px;word-break: break-all;overflow-y: auto; width: calc(100% - 20px);
}
</style>