<template>
    <div class="msgctn" :style="{height : state.vh}">
        <div class="msgsctn">
            <div class="msgsctnhd">
                <h4>Messages</h4>
            </div>
            <div class="msgswrap">
                <div class="msg">
                    <img src="https://photo.candorjab.com/img?img=dp.png" />
                    <div class="msg-dtl">
                        <p><b>Martins Justin</b> </p>
                        <small>10 minutes ago</small>
                    </div>
                </div>
                <div class="msg">
                    <img src="https://photo.candorjab.com/img?img=dp.png" />
                    <div class="msg-dtl">
                        <p><b>Martins Justin</b> </p>
                        <small>10 minutes ago</small>
                    </div>
                </div>
                <div class="msg">
                    <img src="https://photo.candorjab.com/img?img=dp.png" />
                    <div class="msg-dtl">
                        <p><b>Martins Justin</b> </p>
                        <small>10 minutes ago</small>
                    </div>
                </div>
                <div class="msg">
                    <img src="https://photo.candorjab.com/img?img=dp.png" />
                    <div class="msg-dtl">
                        <p><b>Martins Justin</b> </p>
                        <small>10 minutes ago</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="msgsvw">
            <div class="msgvwhsd">
                <div class="rcvdtl">
                    <img src="https://photo.candorjab.com/img?img=dp.png" />
                    <div>
                        <p>Martins James</p>
                        <small>active 15m ago</small>
                    </div>
                </div>
            </div>
            <div class="msgvwmsg">
                <div class="talk-bubble-wrap me">
                    <div class="talk-bubble">
                        <p> Good morning bro</p>
                    </div>
                </div>
                <div class="talk-bubble-wrap you">
                    <div class="talk-bubble">
                        <p> Yo!!! what's up? it's been a while</p>
                    </div>
                </div>
                <div class="talk-bubble-wrap me">
                    <div class="talk-bubble">
                        <p> I know right? hope you've been good</p>
                    </div>
                </div>
                <div class="talk-bubble-wrap you">
                    <div class="talk-bubble">
                        <p> yh, i have, you know how it is</p>
                    </div>
                </div>
                <div class="talk-bubble-wrap me" style="padding-bottom: 6px;">
                    <div class="talk-bubble">
                        <p> yh, i do</p>
                    </div>
                </div>
                <div class="talk-bubble-wrap me" style="padding-top: 0px;">
                    <div class="talk-bubble">
                        <p> Hope the family is doing just as well?</p>
                    </div>
                </div>
                <div class="talk-bubble-wrap you">
                    <div class="talk-bubble">
                        <p> the family is great, Ade just graduated medical school, he's currently practicing medicine
                        in the San Adre's Medical Hospital here in texas, you should visit him sometime</p>
                    </div>
                </div>
            </div>
            <div class="nwmsg">
                <div class="nwmsg-in">
                    <div class="dpwrap">
                        <img :src=user.dp alt="User Image">
                    </div>
                    <div class="msgwrap">
                        <input type="text" placeholder="Send your message" />
                    </div>
                </div>
            </div>
        </div>
        <div class="msginf">
            <div class="msginf-in">
                <div class="msgsnd-dtl">
                    <img src="https://photo.candorjab.com/img?img=dp.png" />
                        <p>Martins Justin</p>
                        <small>10 minutes ago</small>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted, reactive,computed} from "vue";
import {useStore} from "vuex";
export default {
  name: 'MessagesView',
  setup(){
    const state=reactive({vh:0,vw:0})
    const store=useStore();
    const user=computed(()=>store.state.User.user);
    onMounted(()=>{
        window.addEventListener('resize', handleWindowSizeChange);
        state.vh=(window.innerHeight-60-20)+"px";
        state.vw=window.innerWidth+"px";
    })
    function handleWindowSizeChange() {
        state.vh=(window.innerHeight-60-20)+"px";
        state.vw=window.innerWidth+"px";
    }
    return {
        state,handleWindowSizeChange,user
    }
  }
}
</script>
<style scoped>
.msgctn{display: flex; width:98%; margin:10px auto; gap: 5px;}
.msgsctn,.msginf{width:25%; height: 100%; background: var(--white-color);
    border-radius: 30px; overflow: hidden;}
.msgsctn .msgsctnhd{padding: 15px 20px;}
.msgsctnhd h4{margin: 0; font-size: 20px;}
.msgswrap{overflow-y: auto; height: 100%; padding: 0 20px;}
.msgswrap .msg{margin: 10px 0; display: flex; align-items: center; padding: 6px 10px;
cursor: pointer; transition: all 0.3s; margin-left: -10px; margin-right: -10px; border-radius: 20px;}
.msgswrap .msg:hover{background: var(--bg-color);}
.msgswrap .msg img{width: 40px; height: 40px; border-radius: 50%;}
.msgswrap .msg .msg-dtl{margin-left: 10px;}
.msgswrap .msg .msg-dtl p{margin: 0;}
.msgswrap .msg .msg-dtl small{display: block;}
.msgsvw{width: 50%; height: 100%; background: var(--white-color);
    border-radius: 30px; overflow: hidden;display: flex; flex-direction: column;}
.msgvwhsd{width: 100%; border-bottom: 2px solid var(--border-color); display: flex;
align-items: center; padding:10px 20px; box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;}
.msgvwhsd .rcvdtl{display: flex; align-items: center}
.rcvdtl img{width: 40px; height: 40px; border-radius: 50%}
.rcvdtl div{padding-left: 10px}
.rcvdtl p{font-weight: bold; margin: 0;}
.rcvdtl small{display: block;}
.msgvwmsg{height: 100%; padding: 15px 20px;}
.msgsvw .nwmsg{padding: 15px 20px;}
.nwmsg-in{display: flex; align-items: center;}
.nwmsg-in .dpwrap{width: 40px; height: 40px; border-radius: 50%; overflow: hidden;}
.nwmsg-in .msgwrap{border:1px solid var(--white-color); border-radius: 15px; width: calc(100% - 50px);
margin-left: 10px; background: var(--bg-color)}
.msgwrap input{border:0; outline: 0; background: transparent; padding: 10px;
width: 100%; color: var(--placeholder-text-color);}
.msgwrap ::placeholder {color: var(--placeholder-text-color); opacity: 1; /* Firefox */}
.msgwrap ::-ms-input-placeholder { color: var(--placeholder-text-color) /* Edge 12 -18 */ }
.msgsnd-dtl{text-align:center; padding:30px 0;}
.msgsnd-dtl img{width:100px; height:100px; border-radius:50%;}
.msgsnd-dtl p{margin:0; font-size:20px; font-weight:bold}
.msgsnd-dtl small{display:block}
</style>