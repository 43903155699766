<template>
  <div class="storieswrap">
    <div class="panel">
      <div class="pnlhdr">
        <div class="pnlhdr-in">
          <div class="xwrap">
            <svg fill="inherit" width="24px" height="24px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
              <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
            </svg>
          </div>
          <router-link class="vxdash-logo" :to="{name : 'home'}">
            <img src="../assets/img/large_logo.png">
          </router-link>
        </div>
      </div>
      <div class="stories">
        <span class="storieshd">
          All Stories
        </span>
        <ProfileStory
          v-for="story in state.stories"
          :key="story.key"
          :dp="story.dp"
          :name="story.name"
          :slides="story.count"
          :time="story.time"
          :id="story.loc"
          @showStory="showStory"
          :class="{'--active': story.loc == state.activeSlide.loc}"
          />
      </div>
    </div>
    <div class="preview" ref="preview">
      <div class="viewer" :style="{width:state.vw, height:state.vh}">
        <div class="strctrlft" v-if="showPrv" v-on:click="showPrev()">
          <div class="ctr-in">
            <div>
              <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor" class="x19dipnz x1lliihq x1k90msu x2h7rmj x1qfuztq" style="--color: var(--secondary-icon);"><path d="M14.791 5.207 8 12l6.793 6.793a1 1 0 1 1-1.415 1.414l-7.5-7.5a1 1 0 0 1 0-1.414l7.5-7.5a1 1 0 1 1 1.415 1.414z"></path></svg>
            </div>
          </div>
        </div>
        <div class="strctrryt" v-if="showNxt" v-on:click="showNextt()">
          <div class="ctr-in">
            <div>
              <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor" class="x19dipnz x1lliihq x1k90msu x2h7rmj x1qfuztq" style="--color:var(--secondary-icon)"><path d="M9.209 5.207 16 12l-6.791 6.793a1 1 0 1 0 1.415 1.414l7.5-7.5a1 1 0 0 0 0-1.414l-7.5-7.5a1 1 0 1 0-1.415 1.414z"></path></svg>
            </div>
          </div>
        </div>
        <div class="view">
          <div ref="viewer"></div>
        </div>
        <div class="header">
          <div class="cnt">
            <div v-for="story in state.story" :key="story.id">
              <div class="fill" :style="{width: story.time}"></div>
            </div>
          </div>
          <div class="ctr">
            <div class="strinf">
              <div class="imgwrp">
                <img src="https://photo.candorjab.com/img?img=dp.png"/>
              </div>
              <p>Martins Ore</p>
              <small>2h</small>
            </div>
            <div class="strctr">
              <div class="play" v-if="!state.play" v-on:click="changeState()">
                <svg viewBox="0 0 24 24" width="24" height="24" fill="inherit" ><path d="m18.477 12.906-9.711 5.919A1.148 1.148 0 0 1 7 17.919V6.081a1.148 1.148 0 0 1 1.766-.906l9.711 5.919a1.046 1.046 0 0 1 0 1.812z"></path></svg>
              </div>
              <div class="pause" v-if="state.play" v-on:click="changeState()">
                <svg viewBox="0 0 24 24" width="24" height="24" fill="inherit"><rect x="7" y="5" width="4" height="14" rx="1.167"></rect><rect x="13" y="5" width="4" height="14" rx="1.167"></rect></svg>
              </div>
              <div class="volon" v-if="state.mute" v-on:click="toggleMute()">
                <svg viewBox="0 0 24 24" width="24" height="24" fill="inherit" ><path d="M13.16 2.124a1.449 1.449 0 0 0-1.563.26l-4.346 4.1a.8.8 0 0 0-.251.58v9.921a.8.8 0 0 0 .255.585l4.35 4.054a1.438 1.438 0 0 0 .97.375 1.466 1.466 0 0 0 .585-.123A1.382 1.382 0 0 0 14 20.6V3.4a1.382 1.382 0 0 0-.84-1.276zM4.25 7A2.25 2.25 0 0 0 2 9.25v5.5A2.25 2.25 0 0 0 4.25 17h.35a.4.4 0 0 0 .4-.4V7.4a.4.4 0 0 0-.4-.4zM21.707 9.293a1 1 0 0 0-1.414 0L19 10.586l-1.293-1.293a1 1 0 0 0-1.414 1.414L17.586 12l-1.293 1.293a1 1 0 1 0 1.414 1.414L19 13.414l1.293 1.293a1 1 0 0 0 1.414-1.414L20.414 12l1.293-1.293a1 1 0 0 0 0-1.414z"></path></svg>
              </div>
              <div class="voloff" v-if="!state.mute" v-on:click="toggleMute()">
                <svg viewBox="0 0 24 24" width="24" height="24" fill="inherit" ><path d="M13.16 2.124a1.449 1.449 0 0 0-1.563.26l-4.346 4.1a.8.8 0 0 0-.251.58v9.921a.8.8 0 0 0 .255.585l4.35 4.054a1.438 1.438 0 0 0 .97.375 1.466 1.466 0 0 0 .585-.123A1.382 1.382 0 0 0 14 20.6V3.4a1.382 1.382 0 0 0-.84-1.276zM4.25 7A2.25 2.25 0 0 0 2 9.25v5.5A2.25 2.25 0 0 0 4.25 17h.35a.4.4 0 0 0 .4-.4V7.4a.4.4 0 0 0-.4-.4zM16.95 7.05a1 1 0 1 0-1.414 1.414 5.008 5.008 0 0 1 0 7.072 1 1 0 1 0 1.414 1.414 7.009 7.009 0 0 0 0-9.9z"></path><path d="M18.364 4.222a1 1 0 0 0 0 1.414 9.01 9.01 0 0 1 0 12.728 1 1 0 1 0 1.414 1.414 11.012 11.012 0 0 0 0-15.556 1 1 0 0 0-1.414 0z"></path></svg>
              </div>
              <div class="opt">
                <svg viewBox="0 0 24 24" width="24" height="24" fill="inherit" ><circle cx="12" cy="12" r="2.5"></circle><circle cx="19.5" cy="12" r="2.5"></circle><circle cx="4.5" cy="12" r="2.5"></circle></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="btm">
          <div class="btm-in">
            <div class="dpwrap">
              <img :src=user.dp alt="User Image">
            </div>
            <div class="msgwrap">
              <input type="text" placeholder="Send your message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted, reactive,onBeforeUnmount,computed} from "vue";
import {useStore} from "vuex";
import ProfileStory from '@/components/ProfileStory.vue'

export default {
  name: 'StoriesView',
  components: {
    ProfileStory
  },
  setup(){
    const store=useStore();
    const user=computed(()=>store.state.User.user);
    const preview=ref(null);
    const viewer=ref(null);
    const state=reactive({vh:0, vw:0,story:[],storyLine:[], time:0,storyPos:0,intv:0,mute:true,play:false,i:0,viewset:false,vid:null,
    slide_duration:30,incre:0.33,finish:false,stories:[],activeSlide:{}})
    const showPrv=computed(()=>{
      return (state.storyPos == 1 && state.i  == 0) ? false : true
    })
    const showNxt=computed(()=>{
      return (state.storyPos == state.storyLine.length && state.i >= (state.story.length -1)) ? false : true;
    })
    onMounted(()=>{
      window.addEventListener('resize', handleWindowSizeChange);
      state.vh=(preview.value.clientHeight)+"px";
      state.vw=((preview.value.clientHeight) /1.5)+"px";
      state.story=[];
      state.stories=[
        {
          key:"1",
          time:"3h",
          name: "Godspower Innoson",
          dp: "https://photo.candorjab.com/img?img=dp.png",
          count:"3 slides",
          loc:"1"
        },{
          key:"2",
          time:"3h",
          name: "Martins Adeson",
          dp: "https://photo.candorjab.com/img?img=dp.jpeg",
          count:"2 slides",
          loc:"2"
        },
      ];
      state.storyLine=[
        [
          {
            key:"4",
            time:"0%",
            type:"img",
            name: "Godspower Innoson",
            dp: "https://photo.candorjab.com/img?img=dp.png",
            file:"https://photo.candorjab.com/img?img=story-two.jpg",
            loc:"1"
          },
          {
            key:"3",
            time:"0%",
            type:"vid",
            name: "Godspower Innoson",
            dp: "https://photo.candorjab.com/img?img=dp.jpeg",
            file:"https://photo.candorjab.com/stream?vid=video.mp4",
            loc:"1"
          },
          {
            key:"4",
            time:"0%",
            type:"img",
            name: "Godspower Innoson",
            dp: "https://photo.candorjab.com/img?img=dp.png",
            file:"https://photo.candorjab.com/img?img=story-one.jpg",
            loc:"1"
          }
        ],
        [
          {
            key:"1",
            time:"0%",
            type:"img",
            name: "Martins Adeson",
            dp: "https://photo.candorjab.com/img?img=dp.png",
            file:"https://photo.candorjab.com/img?img=story-two.jpg",
            loc:"2"
          },
          {
            key:"5",
            time:"0%",
            type:"vid",
            name: "Martins Adeson",
            dp: "https://photo.candorjab.com/img?img=dp.jpeg",
            file:"https://photo.candorjab.com/stream?vid=video2.mp4",
            loc:"2"
          }
        ]
      ];
      state.storyPos=1;
      showNext()
    })
    onBeforeUnmount(()=>{
      window.removeEventListener('resize', handleWindowSizeChange);
      clearInterval(state.intv)
    })

    function handleWindowSizeChange() {
      state.vh=(preview.value.clientHeight)+"px";
      state.vw=((preview.value.clientHeight) /1.5)+"px";
      if(state.vid){
        state.vid.style.width=state.vw;
        state.vid.style.height=state.vh;
      }
    }

    function setView(){
      state.activeSlide=state.story[state.i];
      if(state.activeSlide.type == "img"){
        viewer.value.innerHTML="<img src='"+state.activeSlide.file+"'/>";
        state.slide_duration=30;
          state.incre=(100*100)/(state.slide_duration*1000);
        //start play when the image is loaded; 
        state.play=true;
      }else if(state.activeSlide.type == "vid"){
        state.vid=document.createElement("video");
        state.vid.src=state.activeSlide.file;
        state.vid.style.width=state.vw;
        state.vid.style.height=state.vh;
        viewer.value.innerHTML="";
        viewer.value.appendChild(state.vid)
        state.vid.addEventListener("loadeddata",()=>{
          state.slide_duration = state.vid.duration;
          state.incre=(100*100)/(state.slide_duration*1000);
          state.vid.play()
          state.play=true;
        })
        state.vid.muted=state.mute;
      }
    }
    

    function showNext(){
      state.story= state.storyLine[state.storyPos -1];
      state.intv=setInterval(() => {
        state.activeSlide=state.story[state.i];
        if(!state.viewset){
          setView()
          state.viewset=true;
        }
        if(state.viewset && state.play && state.vid){
          state.vid.play();
        }
        if(state.play){
          state.time+=state.incre; 
          state.activeSlide.time=state.time+"%";
        }
        if(state.time >= 100){
          state.i++;
          if(state.i >= state.story.length){
            //check if there is another story in line and show it else clear interval
            state.mute=true; 
            if(state.storyPos < state.storyLine.length){
              for(var e=0; e < state.story.length; e++){
                state.story[e].time=0;
              }
              state.storyPos++;
              state.story=state.storyLine[state.storyPos -1]
              state.i=0;
            }else{
              //clear interval
              state.finish=true;
              clearInterval(state.intv)
            }
          }
          state.viewset=false;
          state.play=false;
          state.vid=null;
          state.time=0;
        }
      }, 100);
    }

    function toggleMute(){
      if(state.mute){state.mute=false}else{state.mute=true}
      if(state.vid){state.vid.muted=state.mute}
    }

    function changeState(){
      if(state.finish){
        return;
      }
      if(state.play){
        clearInterval(state.intv)
        state.play=false;
        if(state.vid){state.vid.pause()}
      }else{
        state.play=true;
        showNext()
      }
    }

    function showPrev(){
      clearInterval(state.intv)
      state.play=false;
      state.time=0;
      state.story[state.i].time=0;
      if(state.vid){state.vid.pause()}
      if(state.i == 0){
        state.storyPos = state.storyPos == 0 ? state.storyPos : state.storyPos-1
        state.story= state.storyLine[state.storyPos -1];
        state.i=0
      }else{
        state.i--
      }
      state.viewset=false;
      state.intv=setInterval(() => {
        state.activeSlide=state.story[state.i];
        if(!state.viewset){
          setView()
          state.viewset=true;
        }
        if(state.play){
          state.time+=state.incre; 
          state.activeSlide.time=state.time+"%";
        }
        if(state.time >= 100){
          state.i++;
          if(state.i >= state.story.length){
            //check if there is another story in line
            state.mute=true; 
            if(state.storyPos < state.storyLine.length){
              state.storyPos++;
              state.story=state.storyLine[state.storyPos -1]
              state.i=0;
              //show the next story
            }else{
              //clear interval or show the next story
              clearInterval(state.intv)
            }
          }
          state.viewset=false;
          state.play=false;
          state.vid=null;
          state.time=0;
        }
      }, 100);
    }

    function showNextt(){
      clearInterval(state.intv)
      state.play=false;
      state.time=0;
      state.story[state.i].time="100%";
      if(state.vid){state.vid.pause()}
      if(state.story.length > (state.i +1)){
        state.i++
      }else{
        for(var e=0; e < state.story.length; e++){
          state.story[e].time=0;
        }
        state.storyPos++
        state.i=0
        state.story= state.storyLine[state.storyPos -1];
      }
      state.viewset=false;
      state.intv=setInterval(() => {
        state.activeSlide=state.story[state.i];
        if(!state.viewset){
          setView()
          state.viewset=true;
        }
        if(state.play){
          state.time+=state.incre; 
          state.activeSlide.time=state.time+"%";
        }
        if(state.time >= 100){
          state.i++;
          if(state.i >= state.story.length){
            //check if there is another story in line
            state.mute=true; 
            if(state.storyPos < state.storyLine.length){
              state.storyPos++;
              state.story=state.storyLine[state.storyPos -1]
              state.i=0;
              //show the next story
            }else{
              //clear interval or show the next story
              clearInterval(state.intv)
            }
          }
          state.viewset=false;
          state.play=false;
          state.vid=null;
          state.time=0;
        }
      }, 100);
    }

    function showStory(loc){
      clearInterval(state.intv)
      state.play=false;
      state.time=0;
      state.story[state.i].time=0;
      if(state.vid){state.vid.pause()}
      
      state.storyPos = loc
      state.story= state.storyLine[state.storyPos -1];
      state.i=0
      state.viewset=false;
      state.intv=setInterval(() => {
        state.activeSlide=state.story[state.i];
        if(!state.viewset){
          setView()
          state.viewset=true;
        }
        if(state.play){
          state.time+=state.incre; 
          state.activeSlide.time=state.time+"%";
        }
        if(state.time >= 100){
          state.i++;
          if(state.i >= state.story.length){
            //check if there is another story in line
            state.mute=true; 
            if(state.storyPos < state.storyLine.length){
              state.storyPos++;
              state.story=state.storyLine[state.storyPos -1]
              state.i=0;
              //show the next story
            }else{
              //clear interval or show the next story
              clearInterval(state.intv)
            }
          }
          state.viewset=false;
          state.play=false;
          state.vid=null;
          state.time=0;
        }
      }, 100);
    }
    
    return{
      preview, state, handleWindowSizeChange, user,
      showNext, viewer, changeState, toggleMute,
      setView, showPrv, showPrev, showNxt,showNextt,showStory
    }
  }
}
</script>

<style scoped>
  .storieswrap{display: flex; position: fixed; top: 0; left: 0;
    z-index: 9999999999; height: 100%; width: 100%;}
  .storieswrap .panel{flex-basis: 20%; width: 20%; background: var(--white-color)}
  .storieswrap .preview{flex-basis: 80%; width: 80%; background: var(--black); display: flex;
  justify-content: center;}
  .preview .viewer{background: var(--black); position:relative}
  .viewer .view{position:absolute; top:0; left:0; bottom: 0; right: 0}
  .header{position: relative; padding: 0 10px;}
  .header .cnt{display:flex; justify-content: space-between; align-items: center;
    height:5px; height: 5px; margin-top: 10px;}
  .cnt div{height:5px; background:rgba(255, 255, 255, 0.1); margin-right: 10px;
    flex: 50%; border-radius: 5px; overflow: hidden}
  .cnt div:last-child{margin-right: 0}
  .cnt div .fill{background-color: var(--white-color); height:100%; width:50%;}
  .header .ctr{display: flex; justify-content: space-between; padding: 5px 0; align-items: center;}
  .ctr .strinf{display:flex; align-items: center;}
  .strinf .imgwrp{width: 40px; height: 40px; border: 2px solid var(--white-color); border-radius: 50%; overflow: hidden; margin-right: 10px;}
  .strinf p, .strinf small{color: var(--white-color); font-weight: bold;}
  .strinf small{padding-left:5px}
  .ctr .strctr{display:flex; fill: var(--white-color); gap: 10px;}
  .strctr > div{cursor: pointer;}
  .strctrlft{position: absolute; left:10px; top: 50%;transform: translateY(-50%); z-index: 1;}
  .strctrryt{position: absolute; right:10px; top: 50%;transform: translateY(-50%);z-index: 1;}
  .ctr-in > div{cursor:pointer; background-color: var(--white-color); width:40px; height:40px;
    border-radius: 50%; display:flex; align-items: center; justify-content: center;
    box-shadow: 0 2px 8px var(--shadow-1), 0 0 0 1px var(--shadow-1); transition: background-color 0.1s linear 0.1s;}
  .ctr-in:hover > div{background-color:#e1dcdc}
  .btm{position: absolute; width: 100%; bottom: 0; overflow: hidden; padding: 10px;}
  .btm .btm-in{display: flex; align-items: center;}
  .btm-in .dpwrap{width: 40px; height: 40px; border-radius: 50%; overflow: hidden;}
  .btm-in .msgwrap{border:1px solid var(--white-color); border-radius: 15px; width: calc(100% - 50px);
    margin-left: 10px; background: rgba(0, 0, 0, .3);}
  .msgwrap input{border:0; outline: 0; background: transparent; padding: 10px;
    width: 100%; color: var(--white-color);}
  .msgwrap ::placeholder {color: var(--white-color); opacity: 1; /* Firefox */}
  .msgwrap ::-ms-input-placeholder { color: var(--white-color) /* Edge 12 -18 */ }
  .panel .pnlhdr{width: 100%; padding: 10px;}
  .panel .pnlhdr .pnlhdr-in{display: flex; align-items: center; border-bottom: 1px solid var(--bg-color); padding-bottom: 10px;}
  .pnlhdr-in .xwrap{width: 30px; height: 30px; background: rgba(0, 0, 0, .8); fill: white;
    display: flex; align-items: center; justify-content: center; border-radius: 50%;}
  .pnlhdr-in .vxdash-logo{width: 40px; display: block; margin-left: 10px; margin-top: 5px;}
  .storieshd{padding: 15px 20px; font-weight: bold; font-size: 20px; display: block; }
  .--active{background-color:#e1dcdc}
</style>
