<template>
  <div class="tm-pt">
    <div class="tm-pt-h">
        <div class="a-img">
            <img :src="posts.dp">
        </div>
        <div class="a-info">
            <p class="tm-pt-a"> <a href="#"> {{posts.name}}</a></p>
            <p class="tm-pt-d">{{posts.date}}</p>
        </div>
        
    </div>
    <div class="tm-pt-tt">
        <p>{{ post.post }}</p>
        <div class="tm-pt-ts" style="text-align:center" v-if="post.type=='img'">
            <div class="tm-pt-im" v-for="(file, index) in post.files" :key="index" @click="previewPost">
              <img :src="file" />
            </div>
        </div>
        <div class="tm-pt-vd" v-if="post.type=='vid'">
            <video :src="post.files[0]" controls></video>
        </div>
        <div class="pst-smr">
            <div class="lyks">
              <svg width="24px" height="24px" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" enable-background="new 0 0 76.00 76.00" xml:space="preserve">
                <path fill="inherit" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 36.8125,14.4232C 36.8125,20.7565 34.0416,26.694 34.0416,25.1107C 34.0416,23.5274 30.875,29.8607 29.2916,33.0274C 27.7083,36.194 26.125,40.5482 26.125,40.5482L 19.7917,40.1524C 19.7917,40.1524 18.2083,54.0065 21.375,55.5899C 24.5417,57.1732 27.8391,54.9558 30.875,56.7774C 38.7916,61.5274 51.4583,58.3607 49.875,55.194C 49.875,55.194 56.2083,52.0274 54.625,50.444C 54.625,50.444 56.2083,48.069 54.2292,45.694C 56.2083,43.319 55.4167,40.944 54.2292,39.3607C 55.4167,36.9857 56.2083,34.6107 51.4583,33.0274C 48.4542,32.026 40.375,35.7982 40.375,31.444C 40.375,29.8607 43.5416,25.1107 43.5416,21.944C 43.5416,15.6107 36.8125,14.4232 36.8125,14.4232 Z "/>
              </svg>
                <span>{{posts.likes}}</span>
            </div>
            <div class="cmm">
                <span>{{posts.comments}}</span>
            </div>
        </div>
    </div>

    <div class="cmt-sec" role="1015675">
        <div class="cmt-row" role="like" :class="{'liked' : posts.isLike}" @click="toggleLike(posts)">
          <svg width="24px" height="24px" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" enable-background="new 0 0 76.00 76.00" xml:space="preserve">
            <path fill="inherit" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 36.8125,14.4232C 36.8125,20.7565 34.0416,26.694 34.0416,25.1107C 34.0416,23.5274 30.875,29.8607 29.2916,33.0274C 27.7083,36.194 26.125,40.5482 26.125,40.5482L 19.7917,40.1524C 19.7917,40.1524 18.2083,54.0065 21.375,55.5899C 24.5417,57.1732 27.8391,54.9558 30.875,56.7774C 38.7916,61.5274 51.4583,58.3607 49.875,55.194C 49.875,55.194 56.2083,52.0274 54.625,50.444C 54.625,50.444 56.2083,48.069 54.2292,45.694C 56.2083,43.319 55.4167,40.944 54.2292,39.3607C 55.4167,36.9857 56.2083,34.6107 51.4583,33.0274C 48.4542,32.026 40.375,35.7982 40.375,31.444C 40.375,29.8607 43.5416,25.1107 43.5416,21.944C 43.5416,15.6107 36.8125,14.4232 36.8125,14.4232 Z "/>
          </svg>
          <span v-if="!posts.isLike">Like</span>
          <span v-if="posts.isLike" style="color:var(--primary-color);">Liked</span>
        </div>

        <div class="cmt-row" role="review" @click="showComment()" ref="comment">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" class="mercado-match" width="24" height="24" focusable="false">
              <path d="M7 9h10v1H7zm0 4h7v-1H7zm16-2a6.78 6.78 0 01-2.84 5.61L12 22v-4H8A7 7 0 018 4h8a7 7 0 017 7zm-2 0a5 5 0 00-5-5H8a5 5 0 000 10h6v2.28L19 15a4.79 4.79 0 002-4z"></path>
            </svg>
            <span>Comment</span>
        </div>
    </div>

    <div class="cmts" id="cmts">
      <div class="u-cmt-row">
        <div class="a-img">
          <img src="https://photo.jevler.com/perm/IMG087979631439.png">
        </div>
        <div class="u-cmt-wrap">
          <p class="u-cmt">
            <a href="profile?acc=">Victory  Christian </a>
            nice design
          </p>
          <div class="u-dtl">
            <span style="float:left">now</span>
          </div>
        </div>
    </div>
  </div>

</div>
</template>
  
  <script>
import { computed, onMounted, ref } from 'vue';
// import { useStore } from 'vuex';
// import { svg } from "../assets/config/svg";

  export default {
    name: 'PostView',
    setup(props,ctx){
      const posts=computed(()=>props.post);
      const index=computed(()=>props.indx);
      const comment=ref(null);
      onMounted(()=>{
        console.log(comment)
      })

      function toggleLike(post){
        if(post.isLike){
          post.isLike=false;
          post.likes--;
        }else{
          post.isLike=true;
          post.likes++;
        }
      }

      function showComment(){
        ctx.emit("showComment",index.value,posts);
      }

      function previewPost(){
        ctx.emit("previewPost",index.value,posts);
      }

      return{
        posts,
        toggleLike,
        showComment,
        index,previewPost
      }
    },
    props:['post','indx']
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tm-pt { width: 100%; padding: 15px 25px; background: white; border: 1px solid #e9e4eb; border-radius: 10px; margin: 10px 0;}
.tm-pt-h {display: flex; padding: 10px 0 0;}
.a-img img {width: 40px; height: 40px; border-radius: 50%;}
.tm-pt-h .a-info {margin-left: 10px;}
.tm-pt-h .a-info .tm-pt-a { font-weight: 500;}
.tm-pt-h .a-info p {margin: 0;padding: 0;color: #626262;}
.tm-pt-tt p {word-break: break-word;}
.tm-pt-ts{width:100%; position: relative; display: flex; align-items: center;}
.tm-pt-vd{width:100%;}
.tm-pt-vd video{width:100%}
.pst-smr {margin-top: 10px; font-size: 12px !important; display: flex; align-items: center; justify-content: space-between;}
.tm-pt-tt .lyks svg { width: 30px; fill: var(--sec-color);}
.tm-pt-tt .lyks {display: flex; align-items: center;}
.tm-pt-tt .cmm span {position: relative; top: 7px;}
.cmt-sec { border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; padding: 5px 0; display: flex; margin-top: 10px}
.cmt-row {cursor: pointer; text-align: center; user-select: none; width: 50%; display: flex; align-items: center; justify-content: center;}
.cmt-row span {margin-left: 10px;}
.liked svg{fill:var(--primary-color)}
.u-cmt-row {padding: 10px 0; display: flex;}
.u-cmt-row .a-img {width: 40px; height: 40px;}
.u-cmt-wrap {width: calc(100% - 50px); margin-left: 10px;}
.u-cmt-wrap p {margin: 0; padding: 10px; min-height: 40px;
  background-color: #f3f3f3; border-radius: 10px; word-break: break-all; max-width: calc(100% - 20px); display: inline-block;}
.u-cmt-wrap p a {font-weight: bold; color: var(--link-color);}
.u-cmt-wrap .u-dtl {width: calc(100% - 20px); font-size: 12px !important;
  margin-top: 5px; color: #525252 !important; font-family: sans-serif;}
.u-cmt-wrap .u-dtl span {margin: 0 8px;}
.tm-pt .nw-cmt {border-radius: 30px; margin: 10px 0 20px; display: flex;}
.nw-cmt .a-img {width: 40px; height: 40px;}
.nw-cmt .p-cmt-wrap {border: 1px solid #ccc; width: calc(100% - 50px); margin-left: 10px; border-radius: 20px;}
.nw-cmt .p-cmt {padding: 10px; margin: 0; outline: none; border: 0; max-height: 50px;word-break: break-all;overflow-y: auto; width: calc(100% - 20px);
}
.tm-pt-im{cursor: pointer;}
</style>  