import { createStore } from 'vuex'
import { UserModule } from './user';
import { LoginModule } from './login';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Login:LoginModule,
    User:UserModule
  }
})
