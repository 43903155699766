export const UserModule={
    namespaced:true,

    state:{
        user:{
            name:"",
            dp:"",
            acc:"",
            links:""
        }
    },

    mutations:{
        SET_USER(state, user){
            state.user=user;
        }
    },

    actions:{
        setUser({commit}, user){
            commit('SET_USER',user);
        }
    },
};