<template>
  <TopNavigation/>
  <router-view/>
</template>

<script>
import TopNavigation from "@/components/TopNavigation";
import {useStore} from "vuex";
import {computed} from "vue";
export default {
  name: 'App',
  setup(){
    const store=useStore();
    const user=computed(()=>store.state.User.user);

    function verifySess(){
      const update = {verifySess: "verifySess"};
      const options = {method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(update)};
      fetch('https://api.candorjab.com/dashboardHandler', options).then(data => {
          if (!data.ok) {
              throw Error(data);
          }
          return data.json();
      }).then(res => {
        console.log(res)
        if(!res.session){
          // window.location="https://api.candorjab.com";
        }else{
          var r=res.msg
          var user={
            name:r.name,
            dp:r.dp,
            acc:r.acc,
            links:r.links
          }
          var login={
              status:true
            }
          store.dispatch("Login/setLogin",login);
          store.dispatch("User/setUser",user);
        }
      }).catch(e => {
          console.warn(e);
          // window.location="https://api.candorjab.com";
      });
    }
    return {
      verifySess,
      user
    }
  },
  beforeCreate: function(){
    this.verifySess();
  },
  components:{TopNavigation}
}
</script>
<style>
@import url("./assets/css/main.css");
</style>