<template>
<div class="notifications-wrap" :style="{height : state.vh}">
  <div class="notifications">
    <h4>Notifications</h4>
    <div class="noti">
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
      <div class="note">
        <img src="http://localhost:8080/websites/photo.candorjab/img?img=dp.png" />
        <div class="note-dtl">
            <p><b>Martins Justin</b> liked your post</p>
            <small>10 minutes ago</small>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {onMounted, reactive,computed} from "vue";
import {useStore} from "vuex";
export default {
  name: 'NotificationsView',
  setup(){
    const state=reactive({vh:0,vw:0})
    const store=useStore();
    const user=computed(()=>store.state.User.user);
    onMounted(()=>{
        window.addEventListener('resize', handleWindowSizeChange);
        state.vh=(window.innerHeight-60-20)+"px";
        state.vw=window.innerWidth+"px";
    })
    function handleWindowSizeChange() {
        state.vh=(window.innerHeight-60-20)+"px";
        state.vw=window.innerWidth+"px";
    }
    return {
        state,handleWindowSizeChange,user
    }
  }
}
</script>

<style scoped>
.notifications-wrap{width:100%; display: flex; justify-content: center;}
.notifications{width:40%; background: var(--white-color); min-height: 100%; margin-top: 30px;
  padding: 20px 30px; border-radius: 40px;}
.notifications h4{margin: 0; font-size: 16px;}
.notifications .noti .note{margin: 10px 0; display: flex; align-items: center; padding: 6px 10px;
cursor: pointer; transition: all 0.3s; margin-left: -10px; margin-right: -10px; border-radius: 20px;}
.notifications .noti .note:hover{background: var(--bg-color);}
.notifications .noti .note img{width: 60px; height: 60px; border-radius: 50%;}
.notifications .noti .note .note-dtl{margin-left: 10px;}
.notifications .noti .note .note-dtl p{margin: 0; font-size: 16px;}
.notifications .noti .note .note-dtl small{display: block;}
</style>