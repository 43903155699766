<template>
    <div class="vxmodal-wrap" :style="{display : display}">
        <div class="vxOverlay"></div>
        <div class="vixPrv">
            <div class="vxclft">
                <div class="pr-wr">
                    <div class="pr-cn">
                        <div class="pr-ctr">
                            <span class="pr">
                                <svg width="24px" height="24px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" version="1.1" xml:space="preserve">
                                    <g id="_x37_16-_back__x2C__left_arrow__x2C__direction__x2C_"><g>
                                        <path d="M366.277,26.814L154.719,238.271c-10.729,10.221-10.729,26.192,0,36.413l211.559,211.455" style="fill:none;stroke:#000000;stroke-width:13.4167;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.6131;"></path></g></g><g id="Layer_1"></g>
                                </svg>
                            </span>
                            <span class="nt">
                                <svg width="24px" height="24px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" version="1.1" xml:space="preserve">
                                    <g id="_x37_17-_next__x2C__arrow__x2C__right_arrow__x2C__direction__x2C_"><g><path d="M146.662,26.814L358.221,238.27c10.755,10.222,10.755,26.193,0,36.414L146.662,486.139" style="fill:none;stroke:#000000;stroke-width:13.4167;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.6131;"></path></g></g><g id="Layer_1"></g>
                                </svg>
                            </span>
                        </div> 
                        <div class="mg-ctn" ref="prv">
                            <img id="mg3hhg3" :src="post.files[0]" >
                        </div> 
                    </div>
                </div>
            </div>
        
            <div class="vxcryt">
                <div class="vxcryt-in">
                    <div class="tm-pt-h">
                        <div class="a-img">
                            <img :src="post.dp" >
                        </div>
                        <div class="a-info">
                            <p class="tm-pt-a">  
                                <a href="#">{{post.name}}</a> 
                            </p>
                            <p class="tm-pt-d">{{post.date}}</p>
                        </div>
                    </div>
                    <div class="tm-pt-tt">
                        <p>
                            {{ post.post }}
                        </p>
                        <div class="lyks">
                            <svg width="24px" height="24px" viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" enable-background="new 0 0 76.00 76.00" xml:space="preserve">
                                <path fill="inherit" fill-opacity="1" stroke-width="0.2" stroke-linejoin="round" d="M 36.8125,14.4232C 36.8125,20.7565 34.0416,26.694 34.0416,25.1107C 34.0416,23.5274 30.875,29.8607 29.2916,33.0274C 27.7083,36.194 26.125,40.5482 26.125,40.5482L 19.7917,40.1524C 19.7917,40.1524 18.2083,54.0065 21.375,55.5899C 24.5417,57.1732 27.8391,54.9558 30.875,56.7774C 38.7916,61.5274 51.4583,58.3607 49.875,55.194C 49.875,55.194 56.2083,52.0274 54.625,50.444C 54.625,50.444 56.2083,48.069 54.2292,45.694C 56.2083,43.319 55.4167,40.944 54.2292,39.3607C 55.4167,36.9857 56.2083,34.6107 51.4583,33.0274C 48.4542,32.026 40.375,35.7982 40.375,31.444C 40.375,29.8607 43.5416,25.1107 43.5416,21.944C 43.5416,15.6107 36.8125,14.4232 36.8125,14.4232 Z "/>
                            </svg>
                            <span>{{post.likes}}</span>
                        </div>
                        <div class="cmm">
                            <span>{{post.comments}}</span>
                        </div>
                    </div>
                    <div class="cmt-sec" role="29985253"></div>
                    <div class="cmts">
                        <div>
                            <div class="u-cmt-row float-wrap">
                                <div class="a-img">
                                    <img src="https://photo.jevler.com/perm/IMG087979631439.png">
                                </div>
                                <div class="u-cmt-wrap">
                                    <p class="u-cmt">cool design</p>
                                    <div class="u-dtl float-wrap">
                                        <span style="float:left">5m</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="xx-xls" @click="closePreview">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" class="mercado-match" width="24" height="24" focusable="false">
                    <path d="M13.42 12L20 18.58 18.58 20 12 13.42 5.42 20 4 18.58 10.58 12 4 5.42 5.42 4 12 10.58 18.58 4 20 5.42z"></path>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
  export default {
    name: 'PostOverlay',
    setup(props,ctx){
        const post=computed(()=>props.Post)
        const display=computed(()=>props.Display);

        function closePreview(){
            ctx.emit("close","1")
        }
        return{
            post,closePreview,
            display
        }
    },
    props:['Post','Display']
  }
</script>

<style scoped>
.vxmodal-wrap{position:fixed; width:100%; height:100%; z-index:99999999999;overflow:hidden; top: 0;}
.vxOverlay{ opacity:.6;position:fixed;top:0;left:0;background:black; width:100%;z-index:9999999; height:100%;
transition:opacity 0.3s linear 0s; -o-transition:opacity 0.3s linear 0s; -webkit-transition:opacity 0.3s linear 0s;
-moz-transition:opacity 0.3s linear 0s; overflow-y: hidden;}
.vixPrv{width:100%; height: 100%; position: absolute; z-index: 999999999999999999999999999999999;}
.vixPrv .vxclft{float: left; width: 65%; background: #000; height: 100%;}
.vxclft .pr-wr{height: 100%; position: relative; width: 100%;}
.pr-cn{position: absolute; width: 100%; top: 50%; transform: translateY(-50%); height: 100%;}
.pr-cn .pr-ctr{height: 100%; position: absolute; top: 50%; left: 0;
    width: 100%; z-index: 9;}
.pr-cn span{padding:10px;display: block; cursor: pointer; background: rgba(255,255,255,0.75);}
.pr-cn .pr{float:left}
.pr-cn .nt{float:right}
.mg-ctn{width:100%; height: 100%; position: relative;}
.mg-ctn img{-webkit-user-select: none; -ms-user-select: none; user-select: none; object-fit: contain;max-width: 100%; max-height: 100%; object-position: center; position: absolute;
    top: 50%; transform: translateY(-50%);}
.xx-xls{cursor: pointer; position: absolute; top:10px; left:10px; z-index: 99999999999999999999999999999;}
.xx-xls div{background: rgba(255,255,255,0.8); padding:20px; border-radius: 50%; position: relative; }
.xx-xls svg{position: absolute; top: 50%; transform: translate(-50%,-50%); left: 50%;}
.vixPrv .vxcryt{float: right; width:35%; height: 100%; background: white;}
.vxcryt .vxcryt-in{padding:10px}
.mobile-top-nav{display: none;}
.ryt-syd{width: var(--right-left-side); float: left;}
.lft-syd{width: var(--right-right-side); float: left; padding: inherit 10px;}
.vx-pg-title{margin: 10px 0; position: relative;}
.vx-pg-title h2{text-transform: uppercase; color: var(--black); margin: 0; padding: 0;}
.vx-pg-title span{display:block; margin: 0; padding: 0; margin-top: -5px;}
.vx-pg-title .vx-pg-title-opt{right: 0; position: absolute; top: 50%; transform: translateY(-50%);}
.u-cmt-row{width:calc(100% - 50px); padding: 10px 0;
    margin-left: 50px;}
.u-cmt-row .a-img {float: left; width: 40px; height: 40px;}
.u-cmt-wrap{float: left; margin-left: 10px; width: calc(100% - 50px);}
.u-cmt-wrap p{margin:0; padding: 10px; min-height: 40px; background-color: #f3f3f3 ; border-radius: 10px;
    word-break: break-all; max-width: calc(100% - 20px); display: inline-block;}
.u-cmt-wrap p a{font-weight: bold; color: var(--link-color);}
.u-cmt-wrap .u-dtl{width:calc(100% - 20px); font-size: 12px !important; margin-top:5px; color: #525252 !important; font-family: sans-serif;}
.u-cmt-wrap .u-dtl span{margin:0 8px}
.u-cmt-wrap .u-dtl svg{width: 12px !important; height: 12px !important; margin-left:8px; color: var(--sec-color);}
.u-cmt-wrap .u-dtl i::after{content:"\00b7"; }
.u-cmt-wrap .u-dtl i{width:1px; height: 1px; color: var(--black); border-radius: 100%; margin:0 4px}
</style>