<template>
    <div class="pStr" @click="showStory(str.id)">
      <div class="pStr-in">
        <div class="pstrdp">
          <img :src=str.dp />
        </div>
        <div class="pstr-inf">
          <p>{{str.name}}</p>
          <div>{{str.slides}} <span></span> {{str.time}}</div>
        </div>
      </div>
    </div>
</template>

<script>
import {computed} from "vue";
export default {
  name: 'ProfileStory',
  setup(props,ctx){
    const str=computed(()=>props);

    function showStory(id){
      ctx.emit("showStory",id);
    }
    return{
        str,
        showStory
    }
  },
  props:['name','dp','id','slides','time']
}
</script>

<style scoped>
.pStr{border-radius: 15px; cursor: pointer; margin: 0 10px;}
.pStr:hover{background-color:#e1dcdc}
.pStr .pStr-in{padding: 15px 10px; display: flex; align-items: center;}
.pStr-in .pstrdp{width: 40px; height: 40px; border-radius: 50%; overflow: hidden;}
.pStr-in .pstr-inf{margin-left: 10px;}
.pstr-inf p{font-size: 14px; margin: 0; font-weight: bold;}
.pstr-inf div{display: flex; align-items: center;}
.pstr-inf span{width: 5px; height: 5px; background-color: var(--black); border-radius: 50%; margin: 0 5px}
</style>