<template>
  <PostOverlay :Post="state.post" :Display="state.overlay" @close="closePreview"/>
  <div class="profile-home">
    <div class="prf-wrap">
      <div class="prf">
        <div class="prf-in">
          <div class="prf-tp">
            <div class="cvr">
              <div class="cvr-in">
                <div class="ccv">
                  <div class="cvr-wrp">
                    <img src="https://photo.candorjab.com/img?img=cover1.jpg"/>
                  </div>
                  <div class="dpwrap">
                    <div class="dp">
                      <img src="https://photo.candorjab.com/img?img=dp.png" />
                    </div>
                    <div class="chnctr">
                      <div class="likup">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.75 8.44434H11.75M13.25 9.94434L13.25 6.94434" stroke-width="1.5" stroke-linecap="round"></path>
                          <path d="M1.25 13.7779C1.25 11.9681 2.52299 10.4266 4.25303 10.1413L4.40886 10.1156C5.79419 9.88724 7.20581 9.88724 8.59113 10.1156L8.74697 10.1413C10.477 10.4266 11.75 11.9681 11.75 13.7779C11.75 14.5602 11.1361 15.1943 10.3789 15.1943H2.6211C1.86386 15.1943 1.25 14.5602 1.25 13.7779Z" stroke="inherit" stroke-width="1.5"></path>
                          <path d="M9.5625 4.64746C9.5625 6.27843 8.19137 7.60059 6.5 7.60059C4.80863 7.60059 3.4375 6.27843 3.4375 4.64746C3.4375 3.0165 4.80863 1.69434 6.5 1.69434C8.19137 1.69434 9.5625 3.0165 9.5625 4.64746Z" stroke-width="1.5"></path>
                        </svg>
                        <span>Link Up</span>
                      </div>
                      <div class="msgusr">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square">
                          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                        </svg>
                        <span>Message</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="prfinf">
              <div class="p-inf">
                <p>Martins John</p>
              </div>
              <div class="p-smr">
                <span>22 Following</span>
                <span>55K Followers</span>
                <span>545 Posts</span>
              </div>
            </div>
          </div>
          <div class="prfctr">
            <a :class="[{ pctr_active: state.postView }, 'pctr' ]" href="#posts">Posts</a>
            <a :class="[{ pctr_active: state.aboutView }, 'pctr' ]" href="#about">About</a>
            <a :class="[{ pctr_active: state.friendsView}, 'pctr' ]" href="#friends">Friends</a>
            <a :class="[{ pctr_active: state.picsView }, 'pctr' ]" href="#photos">Pictures</a>
            <a :class="[{ pctr_active: state.postVie }, 'pctr' ]">Videos</a>
          </div>
        </div>
        <div class="prfnav">
          <div class="pstswrp" v-if="state.postView">
            <div class="prfsmr">
              <div class="prf-intr">
                <div class="hdwrp">
                  <h3 class="hd">
                    Intro
                  </h3>
                  <p>Welcome to the official Candorjab Profile of Martnis Ade</p>
                </div>
                <div class="prf-mr">
                  <div class="mr">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.875 12.5729C5.30847 11.2498 5 9.84107 5 8.51463C5 4.9167 8.13401 2 12 2C15.866 2 19 4.9167 19 8.51463C19 12.0844 16.7658 16.2499 13.2801 17.7396C12.4675 18.0868 11.5325 18.0868 10.7199 17.7396C9.60664 17.2638 8.62102 16.5151 7.79508 15.6" stroke="inherit" stroke-width="1.5" stroke-linecap="round"/>
                      <path d="M14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" stroke="inherit" stroke-width="1.5"/>
                      <path d="M20.9605 15.5C21.6259 16.1025 22 16.7816 22 17.5C22 18.4251 21.3797 19.285 20.3161 20M3.03947 15.5C2.37412 16.1025 2 16.7816 2 17.5C2 19.9853 6.47715 22 12 22C13.6529 22 15.2122 21.8195 16.5858 21.5" stroke="inherit" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    <span>Lives in Rivers State, Nigeria</span>
                  </div>
                  <div class="mr">
                    <svg height="24px" width="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                        viewBox="0 0 245.827 245.827" xml:space="preserve">
                      <g>
                        <g>
                          <g>
                            <path style="fill:inherit;" d="M223.336,148.384l-0.137-23.527l22.628-12.662L122.576,47.195L0,113.495l49.144,28.216
                              l0.098,16.766l0.01,1.339l0.449-0.215c-0.518,0.703-0.85,1.426-0.84,2.149c0.039,8.246,33.326,14.772,74.41,14.548
                              c41.064-0.215,74.302-7.122,74.273-15.349c0-0.723-0.381-1.426-0.889-2.149l0.449,0.215v-1.339l-0.088-16.834l21.309-13.258
                              l0.117,20.83c-2.345,1.006-3.976,3.312-3.957,6.009c0.02,3.537,2.892,6.399,6.458,6.37c3.586-0.02,6.429-2.912,6.409-6.439
                              C227.332,151.657,225.691,149.371,223.336,148.384z M123.241,170.621c-36.452,0.205-66.017-3.801-66.046-8.91
                              c-0.029-5.11,29.496-9.399,65.949-9.585c36.462-0.205,66.017,3.781,66.037,8.881
                              C189.209,166.098,159.703,170.426,123.241,170.621z M195.335,127.183c-4.934-5.188-22.618-18.886-72.426-18.602
                              c-49.877,0.264-67.336,14.128-72.211,19.394l-0.029-4.963c0,0,14.147-21.524,72.202-21.827
                              c58.025-0.313,72.436,21.045,72.436,21.045L195.335,127.183z M215.755,162.199l-2.511,36.433
                              c7.767-12.203,14.255-7.66,14.255-7.66l-0.156-28.832C218.998,165.414,215.755,162.199,215.755,162.199z"/>
                          </g>
                        </g>
                      </g>
                      </svg>
                    <span>Studied at <b>GGS New York, USA</b></span>
                  </div>
                  
                </div>
              </div>

              <div class="prf-intr">
                <div class="hdwrp">
                  <h3 class="hd" style="border:0">
                    Bio
                  </h3>
                  <p>I am a handsome dude who respect the laws of nature and money</p>
                </div>
              </div>

              <div class="prf-intr">
                <h3 class="hd">
                  Recent Photos
                </h3>
                <div class="prf-phts">
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pht">
                    <div class="pht-in">
                      <div class="phtw">
                        <div class="phtimg">
                          <img src="https://photo.candorjab.com/img?img=dp.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div class="psts">
              <PostView 
              v-for="(post,index) in state.posts"
              :key="post.id"
              :post="post"
              :indx="index"
              @showComment="showComment"
              @previewPost="previewPost"
              ref="post"
            />
            </div>
          </div>
          <div class="abtwrp" v-if="state.aboutView">
            <div class="abtwrp-in">
              <div class="abtwrphd">
                <p>Martins John</p>
                <span>
                  <svg fill="inherit" height="24" width="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                      viewBox="0 0 512 512" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M172.211,41.609
                          c-24.934,27.119-44.68,66.125-56.755,111.992H49.749C75.179,102.741,118.869,62.524,172.211,41.609z M25.6,256
                          c0-26.999,5.077-52.727,13.662-76.8h70.494c-4.608,24.294-7.356,49.963-7.356,76.8s2.748,52.506,7.347,76.8H39.262
                          C30.677,308.727,25.6,283,25.6,256z M49.749,358.4h65.707c12.083,45.867,31.821,84.872,56.755,111.991
                          C118.869,449.476,75.179,409.259,49.749,358.4z M243.2,485.188c-43.81-8.252-81.877-58.24-101.359-126.788H243.2V485.188z
                          M243.2,332.8H135.74c-4.924-24.166-7.74-49.997-7.74-76.8s2.816-52.634,7.74-76.8H243.2V332.8z M243.2,153.6H141.841
                          C161.323,85.052,199.39,35.063,243.2,26.812V153.6z M462.251,153.6h-65.707c-12.083-45.867-31.821-84.873-56.755-111.992
                          C393.131,62.524,436.821,102.741,462.251,153.6z M268.8,26.812c43.81,8.252,81.877,58.24,101.359,126.788H268.8V26.812z
                          M268.8,179.2h107.46c4.924,24.166,7.74,49.997,7.74,76.8s-2.816,52.634-7.74,76.8H268.8V179.2z M268.8,485.188V358.4h101.359
                          C350.677,426.948,312.61,476.937,268.8,485.188z M339.789,470.391c24.934-27.127,44.672-66.125,56.755-111.991h65.707
                          C436.821,409.259,393.131,449.476,339.789,470.391z M402.244,332.8c4.608-24.294,7.356-49.963,7.356-76.8
                          s-2.748-52.506-7.347-76.8h70.494c8.576,24.073,13.653,49.801,13.653,76.8c0,27-5.077,52.727-13.662,76.8H402.244z"/>
                      </g>
                    </g>
                  </svg>
                  Copy link
                </span>
              </div>
            </div>
          </div>
          <div class="picwrp" v-if="state.picsView">
            <div class="picwrp-in">
              <p class="inhd">
                Photos
              </p>
              <div class="picwrps">
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
                <div class="picwr">
                  <div class="picwr-in">
                    <div class="picin">
                      <img src="https://photo.candorjab.com/img?img=dp.png"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="frndwrp" v-if="state.friendsView">
            <div class="frndwrp-in">
              <p class="inhd">
                Friends
              </p>
              <div class="frnds">
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
                <div class="frnd">
                  <img src="https://photo.candorjab.com/img?img=dp.png" />
                  <div class="frnd-dtl">
                    <p>Martins Justin</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostView from '@/components/PostView.vue'
import PostOverlay from '@/components/PostOverlay.vue'
import { onMounted, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'FeedView',
  components: {
    PostView,
    PostOverlay
  },
  setup(){
    const state=reactive({posts:[],overlay:"none",postView:true, hashtag:location.hash,picsView:false,aboutView:false,
    friendsView:false,
      post:{
        id:"",
        dp:"",
        name:"",
        date:"",
        post:"",
        type:"txt",
        files:[""],
        isLike:true,
        likes:"23",
        comments:"10 comments"
      }
    });
    const post=new ref([])
    const store=useStore();
    const user = computed(() => store.state.User.user);

    function createNode(a){let b=document.createElement("div");b.innerHTML=a;return b.firstChild;}

    function showComment(id,p){
      p=p.value;
      const e=post.value[id].$refs.comment;
      const parent=e.parentNode.parentNode;
      if(parent.querySelector(".nw-cmt") !== null){
        parent.querySelector(".nw-cmt .p-cmt").focus();
        return;
      }
      var dp=user.value.dp;
      var cmm="<div class='nw-cmt float-wrap' role=''>"+
              "<div class='a-img'>"+
                  "<img src='"+dp+"'/>"+
              "</div>"+
              "<div class='p-cmt-wrap' role='"+p.id+"'>"+
                  "<p class='p-cmt' contenteditable='true'></p>"+
              "</div>"+
          "</div>";
      parent.append(createNode(cmm))
      const ele=parent.querySelector(".p-cmt")
      ele.setAttribute("value","");
      
      const placeholder = "Alright, Let's hear it!";
      
      ele.innerHTML === '' && (ele.innerHTML = placeholder);             
      ele.addEventListener('focus', function (e) {
          const value = e.target.innerHTML;
          value === placeholder && (e.target.innerHTML = '');
      });             
      ele.addEventListener('blur', function (e) {
          const value = e.target.innerHTML;
          value === '' && (e.target.innerHTML = placeholder);
          e.target.setAttribute("value",value);
      });

      ele.addEventListener("keypress", function(event) {
          if (event.key === "Enter") {
              event.preventDefault();
              ele.innerHTML !== '' && (new pstcm(ele))
          }
      });
    }

    function pstcm(e){
      this.p=e.parentNode.parentNode.parentNode;
      var revs=this.p.querySelector(".tm-pt-tt > .pst-smr > .cmm > span");
      this.p_id=e.parentNode.getAttribute("role");
      this.dp=user.value.dp;
      this.cmm=e.innerHTML;
        this.nw_cmt="<div class='u-cmt-row float-wrap' style='opacity:0.5'>"+
            "<div class='a-img'>"+
                "<img src='"+this.dp+"'/>"+
            "</div>"+
            "<div class='u-cmt-wrap'>"+
                "<p class='u-cmt'>"+this.cmm+
                "</p>"+
            "</div>"+
        "</div>";
        this.cm=createNode("<div></div>");
        var cm=this.cm,
        cmm=this.cmm;
        this.cm.append(createNode(this.nw_cmt));
        this.p.querySelector("#cmts").append(this.cm);
        e.innerHTML="";
        setTimeout(() => {
          var nw_cm="<div class='u-cmt-row float-wrap'>"+
                        "<div class='a-img'>"+
                            "<img src='"+this.dp+"'/>"+
                        "</div>"+
                        "<div class='u-cmt-wrap'>"+
                            "<p class='u-cmt'>"+cmm+
                            "</p>"+
                            "<div class='u-dtl float-wrap'>"+
                                "<span style='float:left'>now</span>"+
                            "</div>"+
                        "</div>"+
                    "</div>";
            cm.innerHTML="";
            cm.append(createNode(nw_cm))
            revs.innerHTML="22 Reviews";
        }, 2000);
    }

    function closePreview(){
      state.overlay="none"
    }

    function previewPost(index,post){
      state.post=post
      state.overlay="block"
    }

    function hideTabs(){
      state.picsView=false
      state.postView=false;
      state.aboutView=false;
      state.friendsView=false;
      var hash=location.hash.substr(1);
        switch (hash) {
          case "about":
            state.aboutView=true;
            break;
          case "photos":
            state.picsView=true;
            break;
          case "friends":
            state.friendsView=true;
            break;
          default:
            state.postView=true;
            break;
        }
    }
    
    onMounted(()=>{
      state.posts=[
        {
          id:"1",
          dp:"https://photo.candorjab.com/img?img=dp1.jpg",
          name:"Sha Kur",
          date:"Apr 7, 2023 by 3:29pm",
          post:"girl's Una sabi sleep oo 😅🤣",
          type:"img",
          files:["https://photo.candorjab.com/img?img=p1.jpg"],
          isLike:true,
          likes:"23",
          comments:"10 comments"
        },
        {
          id:"2",
          dp:"https://photo.candorjab.com/img?img=dp.png",
          name:"Blessed Beyond Measures",
          date:"Apr 7, 2023 by 3:19pm",
          post:"Good evening everyone, hope we are doing very well this wonderful day",
          type:"img",
          files:["https://photo.candorjab.com/img?img=p2.jpg","https://photo.candorjab.com/img?img=p3.jpg"],
          isLike:false,
          likes:"12",
          comments:"7 comments"
        },
        {
          id:"3",
          dp:"https://photo.candorjab.com/img?img=dp1.jpg",
          name:"Blessed Beyond Measures",
          date:"Apr 7, 2023 by 3:19pm",
          post:"My Video",
          type:"vid",
          files:["https://photo.candorjab.com/stream?vid=video3.mp4"],
          isLike:false,
          likes:"12",
          comments:"7 comments"
        }
      ]
      hideTabs();
      window.onhashchange =function(){
        hideTabs();
      }
    })

    return{
      state,
      showComment,
      post,
      createNode,
      pstcm,previewPost,closePreview,hideTabs,
      user
    }
    
    
  }
}
</script>

<style scoped>
.profile-home{width:100%; justify-content: center; display: flex; background: var(--white-color);}
.prf-wrap{width:min(100%, 1200px); display: flex; justify-content: center;}
.prf{width:80%;}
.prf-in{padding: 5px 10px;}
.cvr{width: 100%; padding-top: 33%; position: relative; overflow: hidden; border-radius: 20px;}
.cvr-in{position: absolute; top: 0; bottom: 0; left: 0; right: 0;}
.ccv{width: 100%; height: 100%; position: relative; display: flex;}
.cvr-wrp{position:absolute; top: 0; bottom: 0; left: 0; right: 0;}
.dpwrap{position: relative; width: 100%; height: 110px; align-self: flex-end; padding: 10px 15px; display: flex;
  justify-content: space-between; align-items: flex-end;}
.dp{width: 90px; height: 90px; border-radius: 50%; overflow: hidden; border:2px solid var(--white-color);}
.chnctr{display: flex; align-items: center;}
.chnctr > div{background: var(--white-color); border-radius: 24px; padding: 10px 20px; cursor: pointer; color: var(--black);
  fill: var(--black); stroke: var(--black); display: flex; align-items: center;}
.chnctr > div span{margin-left: 5px}
.chnctr .likup{background: var(--primary-color); color: var(--white-color);
  fill: var(--white-color); stroke: var(--white-color);}
.prfinf{width:100%; display: flex; align-items: center; justify-content: space-between; padding: 10px;}
.p-inf p{font-size: 23px; font-weight: bold; margin: 0;padding: 5px 0;}
.prfinf .p-smr{display: flex;}
.p-smr span{padding: 10px 5px; font-weight: bold; font-size: 13px;}
.prfnav{padding: 10px;}
.prfctr{padding: 0 10px 0 0; display: flex;}
.prfctr .pctr{margin-right: 10px; background: var(--light-bg); padding: 10px 20px;
    border-radius: 10px; cursor: pointer;}
.prfctr .pctr_active {background: var(--bg-color); font-weight: bold}
.pstswrp{display: flex;}
.pstswrp .psts{width: 65%;}
.pstswrp .prfsmr{width:35%}
.prf-intr{border: 1px solid #e9e4eb; border-radius: 10px; padding: 10px 20px; margin-right: 10px; margin-top: 10px;}
.prf-intr .hd{font-weight: bold; margin: 0; padding-top: 10px; color: var(--placeholder-text-color);}
.prf-intr p{text-align: center; margin: 0; padding: 5px 0; color: var(--placeholder-text-color);}
.hdwrp{border-bottom: 1px solid #CED0D4; padding: 5px 0 10px;}
.prf-mr{padding: 15px 0;}
.prf-mr .mr{display: flex; fill: var(--placeholder-text-color); stroke: var(--placeholder-text-color);
  color: var(--placeholder-text-color); margin-bottom: 10px; align-items: center;}
.prf-mr .mr span{margin-left: 10px;}
.prf-phts{display: flex; flex-wrap: wrap;}
.prf-phts .pht{width: 33.3%;}
.prf-phts .pht .pht-in{width: 100%; padding-top: 100%; position: relative}
.pht-in .phtw{position: absolute; width: 100%; height: 100%;top: 0; left: 0; padding: 3px;}
.phtw .phtimg{overflow: hidden; border-radius: 10px; width: 100%; height: 100%;}
.abtwrp, .picwrp, .frndwrp{width: 100%; margin: 10px 0; border: 1px solid #e9e4eb; padding: 10px 15px;
  border-radius: 20px;}
.abtwrp .abtwrp-in{width: 100%}
.abtwrp-in .abtwrphd{display: flex; justify-content: space-between; align-items: center; }
.abtwrphd p{font-size: 30px; font-weight: bold; color: var(--black); margin:0;}
.abtwrphd span{background: var(--bg-color); display: flex; align-items: center;
  padding: 10px 30px; border-radius: 40px; cursor: pointer; font-size: 13px;
  font-weight: bold;}
.abtwrphd span svg{margin-right:10px; width: 14px; height: 14px;}
.inhd{font-size: 18px; font-weight: bold; margin: 10px 0;}
.picwrps{display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }
.picwrps .picwr{width: 25%;}
.picwr-in{width: 100%; padding-top: 100%; position: relative;}
.picwr-in .picin{position: absolute; width: 97%; height: 97%; top:0; left: 0; border-radius: 10px;
  overflow: hidden; cursor: pointer;}
.frndwrp .frnds{width: 100%; display: flex; flex-wrap: wrap;}
.frnds .frnd{width: 50%; padding: 10px 20px; display: flex; align-items: center; padding-left: 0;}
.frnd img{width: 60px; border-radius: 10px; margin-right: 10px}
.frnd .frnd-dtl p{font-size: 15px; font-weight: bold; margin: 0;}
</style>