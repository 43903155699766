<template>
    <div class="str" :style="{flexBasis : fW}">
        <div>
          <router-link :to="{name : 'viewStory', params:{stid: str.id}}">
              <div class="strd">
                <div>
                  <div class="strmn">
                    <img :src=str.prv />
                  </div>
                  <div class="strinf">
                    <div class="str-dp">
                      <img :src=str.dp />
                    </div>
                    <div class="strnm">
                      <p>{{ str.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
          </router-link>
        </div>
      </div>
</template>

<script>
import {computed} from "vue";
export default {
  name: 'StoryView',
  setup(props){
    const fW= computed(()=>props.width);
    const str=computed(()=>props);
    return{
        fW,
        str
    }
  },
  props:['width','name','prv','dp','id']
}
</script>

<style scoped>
.str{position:relative; margin-right:8px;}
  .str a{position: relative;}
  .str .strd{position: relative; padding-top: 155.17%; border-radius: 10px; overflow: hidden;}
  .strd > div{position: absolute; top:0; bottom:0; left:0; right:0}
  .strd .strmn{position: absolute; top: 0; bottom: 0; left: 0; right: 0;}
  .strd .strinf{display: flex; flex-direction: column; position:relative; z-index: 0;
    justify-content: space-between; width: 100%; height: 100%;}
  .strinf .str-dp{width: 40px; height: 40px; margin: 12px;  border-radius: 50%; overflow: hidden; border: 4px solid var(--primary-color);}
  .strinf .strnm{padding: 10px;}
  .strnm p{display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;
  color: var(--white-color); margin: 0; font-weight: bold; font-size: .8125rem; max-width: 90%;
    line-height: 1em;}
</style>