<template>
    <header class="tnav float-wrap">
        <div class="vx-00 float-wrap">
            <a href="javascript:void(0);" class="vxdash-sidebar-toggle" role="button">
                <span class="sr-only">Toggle navigation</span>
            </a>
            <router-link class="vxdash-logo" :to="{name : 'home'}">
                <img src="../assets/img/large_logo.png">
                <b>Candarjab</b>
            </router-link>
        </div>
        

        <nav class="vxdash-navbar float-wrap" role="navigation">
            <div class="vxdash-navbar-custom-menu">
                <ul class="vxdash-navbar-nav float-wrap">
                    <li>
                        <form action="search" method="GET" :class="[{ active: state.focus }, '' ]">
                            <input type="text" name="search" placeholder="Search Here" class="input-v" 
                            @focus="state.focus = true" autocomplete="off"/>
                            <svg fill="inherit" height="16" width="16" version="1.1" class="src-ic"
                                viewBox="0 0 488.4 488.4" xml:space="preserve">
                                <g>
                                    <g>
                                        <path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
                                            s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
                                            S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
                                            S381.9,104.65,381.9,203.25z"/>
                                    </g>
                                </g>
                            </svg>
                            <svg @click="state.focus = false" fill="inherit" width="24px" height="24px" viewBox="0 0 256 256" class="src-cnc">
                                <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
                            </svg>
                            <div class="rcntsrc">
                                <h4>Recent Searches</h4>
                                <div class="srchs">
                                    <div class="srch">
                                        <div class="srch-inf">
                                            <img src="https://photo.candorjab.com/img?img=dp.png"/>
                                            <p>Martins John</p>
                                        </div>
                                        <div class="srchtrsh">
                                            <svg fill="inherit" width="24px" height="24px" viewBox="0 0 256 256">
                                                <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="srch">
                                        <div class="srch-inf">
                                            <img src="https://photo.candorjab.com/img?img=dp.png"/>
                                            <p>Martins John</p>
                                        </div>
                                        <div class="srchtrsh">
                                            <svg fill="inherit" width="24px" height="24px" viewBox="0 0 256 256">
                                                <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="srch">
                                        <div class="srch-inf">
                                            <img src="https://photo.candorjab.com/img?img=dp.png"/>
                                            <p>Adebinsi Edmund</p>
                                        </div>
                                        <div class="srchtrsh">
                                            <svg fill="inherit" width="24px" height="24px" viewBox="0 0 256 256">
                                                <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="srch">
                                        <div class="srch-inf">
                                            <img src="https://photo.candorjab.com/img?img=dp.png"/>
                                            <p>Elijah Barry</p>
                                        </div>
                                        <div class="srchtrsh">
                                            <svg fill="inherit" width="24px" height="24px" viewBox="0 0 256 256">
                                                <path d="M202.82861,197.17188a3.99991,3.99991,0,1,1-5.65722,5.65624L128,133.65723,58.82861,202.82812a3.99991,3.99991,0,0,1-5.65722-5.65624L122.343,128,53.17139,58.82812a3.99991,3.99991,0,0,1,5.65722-5.65624L128,122.34277l69.17139-69.17089a3.99991,3.99991,0,0,1,5.65722,5.65624L133.657,128Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </li>
                </ul>
            </div>

            <div class="vx-end float-wrap">
                <div tabindex="1" :class="[{ active: state.msgView }, 'tpnv' ]">
                    <div class="tpnv-in" @click="state.msgView = state.msgView ? false : closeTabs()">
                        <div class="tpnvsv">
                            <svg fill="inherit" height="18" width="18" version="1.1" id="Layer_1" viewBox="0 0 458 458" xml:space="preserve">
                                <g>
                                    <g>
                                        <path d="M428,41.534H30c-16.569,0-30,13.431-30,30v252c0,16.568,13.432,30,30,30h132.1l43.942,52.243
                                            c5.7,6.777,14.103,10.69,22.959,10.69c8.856,0,17.258-3.912,22.959-10.69l43.942-52.243H428c16.568,0,30-13.432,30-30v-252
                                            C458,54.965,444.568,41.534,428,41.534z M323.916,281.534H82.854c-8.284,0-15-6.716-15-15s6.716-15,15-15h241.062
                                            c8.284,0,15,6.716,15,15S332.2,281.534,323.916,281.534z M67.854,198.755c0-8.284,6.716-15,15-15h185.103c8.284,0,15,6.716,15,15
                                            s-6.716,15-15,15H82.854C74.57,213.755,67.854,207.039,67.854,198.755z M375.146,145.974H82.854c-8.284,0-15-6.716-15-15
                                            s6.716-15,15-15h292.291c8.284,0,15,6.716,15,15C390.146,139.258,383.43,145.974,375.146,145.974z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="tip" v-if="!state.msgView">Messages</div>
                    </div>
                    <div class="tpnvmre noti">
                        <div class="tpnvmrehd">
                            <h4>Messages</h4>

                            <router-link  :to="{name : 'messages'}" @click="closeTabs(); state.msgView = false" tabindex="1">
                                See all
                            </router-link>
                        </div>
                        
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b></p>
                                <small>Hello What's up nah?</small>
                            </div>
                        </div>
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b></p>
                                <small>Hello What's up nah?</small>
                            </div>
                        </div>
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b></p>
                                <small>Hello What's up nah?</small>
                            </div>
                        </div>
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b></p>
                                <small>Hello What's up nah?</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div tabindex="1" :class="[{ active: state.notificationView }, 'tpnv' ]">
                    <div class="tpnv-in" @click="state.notificationView = state.notificationView ? false : closeTabs()">
                        <div class="tpnvsv">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
                                <g id="style=fill">
                                    <g>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.802 19.8317C15.4184 19.7699 15.8349 20.4242 15.5437 20.9539C15.3385 21.3271 15.0493 21.6529 14.7029 21.9197C14.3496 22.1918 13.9397 22.4006 13.5 22.5408C13.0601 22.6812 12.593 22.7522 12.1242 22.7522C11.6554 22.7522 11.1883 22.6812 10.7484 22.5408C10.3087 22.4006 9.89883 22.1918 9.54556 21.9197C9.1991 21.6529 8.90988 21.3271 8.70472 20.9539C8.41354 20.4242 8.83002 19.7699 9.44644 19.8317C9.63869 19.851 11.1433 19.9981 12.1242 19.9981C13.1051 19.9981 14.6097 19.851 14.802 19.8317Z" fill="inherit"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52901 2.08755C10.7932 1.00445 13.4465 0.967602 15.7423 1.98737L15.9475 2.07851C18.3532 3.14707 19.8934 5.4622 19.8934 8.0096L19.8934 9.27297C19.8934 10.2885 20.1236 11.2918 20.5681 12.213L20.8335 12.7632C22.0525 15.29 20.465 18.2435 17.6156 18.7498L17.455 18.7783C13.93 19.4046 10.3154 19.4046 6.79044 18.7783C3.90274 18.2653 2.37502 15.1943 3.77239 12.7115L3.99943 12.3082C4.55987 11.3124 4.85335 10.1981 4.85335 9.06596L4.85335 7.79233C4.85335 5.3744 6.27704 3.16478 8.52901 2.08755Z" fill="inherit"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="tip" v-if="!state.notificationView">Notifications</div>
                    </div>
                    <div class="tpnvmre noti">
                        <div class="tpnvmrehd">
                            <h4>Notifications</h4>

                            <router-link  :to="{name : 'notifications'}" @click="closeTabs(); state.notificationView = false" tabindex="1">
                                See all
                            </router-link>
                        </div>

                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b> liked your post</p>
                                <small>10 minutes ago</small>
                            </div>
                        </div>
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b> liked your post</p>
                                <small>10 minutes ago</small>
                            </div>
                        </div>
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b> liked your post</p>
                                <small>10 minutes ago</small>
                            </div>
                        </div>
                        <div class="note">
                            <img src="https://photo.candorjab.com/img?img=dp.png" />
                            <div class="note-dtl">
                                <p><b>Martins Justin</b> liked your post</p>
                                <small>10 minutes ago</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vx-end-wrap">
                    <span class="vxdash-dropdown  user user-menu">
                        <a href="javascript:void(0);" class="vxdash-dropdown-toggle" data-toggle="dropdown">
                                <div class="usr-img online">
                                    <img id="f_dp" :src=user.dp class="vxdash-user-image" alt="User Image">
                                </div>
                                <span class="hidden-xs">{{ user.name }}</span>                        
                        </a>
                        <ul class="vxdash-dropdown-menu">
                            <a href="profile">
                            <li class="user-header float-wrap">
                                <div class="img">
                                    <img :src=user.dp class="vxdash-user-image" alt="User Image">
                                </div>
                                <div class="pinfo">
                                    <p>
                                    {{  user.name }}                                    <small>Service Provider</small>
                                    </p>
                                </div>
                                
                            </li></a>
                            <li class="user-body">
                                <a href="Pset-1">
                                    <div class="col-xs-4 text-center">
                                        Profile Settings
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="col-xs-4 text-center">
                                        Upgrade Account <small style="color:#90c">(coming soon)</small>
                                    </div>
                                </a>
                                <a href="signout">
                                    <div class="user-footer">
                                        Sign Out
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </span>
                </div>
            </div>
        </nav>

        
    </header>
  </template>
  
<script>
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
    export default {
        name: 'TopNavigation',
        setup(){
            const state=reactive({focus:false,notificationView:false,msgView:false})
            const store= useStore();
            const user = computed(() => store.state.User.user);
            function closeTabs(){
                state.notificationView=false
                state.msgView=false
                return true
            }
            return {
                user,state,closeTabs
            }
        }
    }
</script>

  