<template>
  <div class="stories">
    <div class="str-wrap" ref="strwrap">
      <div class="strwrp-in" ref="strwrp_in">
        <StoryView 
          :width="state.fW"
          v-for="story in state.stories"
          :key="story.id"
          :name="story.name"
          :prv="story.img"
          :dp="story.dp"
          :id="story.id"
        />
      </div>
    </div>
    <div class="strctrlft" v-if="state.showPrv" v-on:click="showPrev()">
      <div class="ctr-in">
        <div>
          <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor" class="x19dipnz x1lliihq x1k90msu x2h7rmj x1qfuztq" style="--color: var(--secondary-icon);"><path d="M14.791 5.207 8 12l6.793 6.793a1 1 0 1 1-1.415 1.414l-7.5-7.5a1 1 0 0 1 0-1.414l7.5-7.5a1 1 0 1 1 1.415 1.414z"></path></svg>
        </div>
      </div>
    </div>
    <div class="strctrryt" v-if="state.showNxt" v-on:click="showNext()">
      <div class="ctr-in">
        <div>
          <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor" class="x19dipnz x1lliihq x1k90msu x2h7rmj x1qfuztq" style="--color:var(--secondary-icon)"><path d="M9.209 5.207 16 12l-6.791 6.793a1 1 0 1 0 1.415 1.414l7.5-7.5a1 1 0 0 0 0-1.414l-7.5-7.5a1 1 0 1 0-1.415 1.414z"></path></svg>
        </div>
      </div>
    </div>
          <!-- <button v-on:click="addStory()">add</button> -->
  </div>
</template>

<script>
import StoryView from "./StoryView";
import {ref, onMounted, computed, reactive } from "vue";
export default {
  name: 'StoriesView',
  setup(){
    const state = reactive({fW:0, tW:0,stories:[],showPrv:false,left:0,
      showNxt:false});
    const strwrap=ref(null);
    const strwrp_in=ref(null)
    onMounted(()=>{
      state.tW=strwrap.value.clientWidth - 32; //padding 8px * 4
      state.fW=computed(()=>(state.tW/4)+"px");
      strwrap.value.style.minHeight=((state.tW/4) * 1.5)+"px";
    })
    
    function getStory(){
      var S=[
        {
          id:"1",
          name:"Martins Adebayo",
          img:"https://photo.candorjab.com/img?img=story-one.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        },
        {
          id:"2",
          name:"Peace Favour",
          img:"https://photo.candorjab.com/img?img=story-two.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        },{
          id:"3",
          name:"marks Johnson",
          img:"https://photo.candorjab.com/img?img=story-one.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        },
        {
          id:"4",
          name:"Adeson Johnson",
          img:"https://photo.candorjab.com/img?img=story-two.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        },{
          id:"5",
          name:"Apostle Femi",
          img:"https://photo.candorjab.com/img?img=story-one.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        },{
          id:"7",
          name:"Joshua Femi",
          img:"https://photo.candorjab.com/img?img=story-one.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        },
      ]
      for(var i=0; i < S.length; i++){
        this.state.stories.push(S[i])
      }
      this.state.showNxt=S.length > 4;
    }

    
    function addStory(){
      this.state.stories.unshift(
        {
          id:this.state.stories.length++,
          name:"John Abobi",
          img:"https://photo.candorjab.com/img?img=story-two.jpg",
          dp:"https://photo.candorjab.com/img?img=dp.png"
        })
    }

    function showNext(){
      var mLeft=((state.tW/4)+8);
      var slft=state.left * -1
      if((slft/mLeft) > (state.stories.length -4)){
        return;
      }
      state.left = state.left == 0 ? mLeft * -1 : state.left-mLeft
      slft=state.left * -1
      strwrp_in.value.style.left=state.left+"px";
      this.state.showPrv=true;
      if((slft/mLeft) == (state.stories.length - 4)){
        this.state.showNxt=false;
      }
    }

    function showPrev(){
      var mLeft=((state.tW/4)+8);
      // var slft=state.left * -1
      if(state.left == 0){
        return;
      }
      state.left = state.left == 0 ? mLeft : state.left+mLeft
      // slft=state.left * -1
      strwrp_in.value.style.left=state.left+"px";
      this.state.showNxt=true;
      if(state.left == 0){
        this.state.showPrv=false;
      }
    }

    return{
      strwrap,
      state,
      getStory,
      addStory,
      showNext,
      strwrp_in,
      showPrev
    }
  },
  beforeCreate: function(){
    this.getStory();
  },
  components:{StoryView}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .stories{background: var(--white-color); width: 100%; padding: 20px 13px;
  border-radius: 20px; position:relative; }
  .stories .str-wrap{overflow: hidden; position: relative;}
  .str-wrap .strwrp-in{display: flex; align-items: center; justify-content: left;
    border-radius: 10px; width: 200000000px; transition:left 0.1s linear 0.1s;
    position: absolute; top: 0; left: 0; bottom: 0;}
  .strctrlft{position: absolute; left:10px; top: 50%;transform: translateY(-50%)}
  .strctrryt{position: absolute; right:10px; top: 50%;transform: translateY(-50%);}
  .ctr-in > div{cursor:pointer; background-color: var(--white-color); width:40px; height:40px;
    border-radius: 50%; display:flex; align-items: center; justify-content: center;
    box-shadow: 0 2px 8px var(--shadow-1), 0 0 0 1px var(--shadow-1); transition: background-color 0.1s linear 0.1s;}
  .ctr-in:hover > div{background-color:#e1dcdc}
</style>
