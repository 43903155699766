import { createRouter, createWebHistory } from 'vue-router'
import FeedView from '../views/FeedView.vue'
import StoriesView from '../views/StoriesView.vue'
import ProfileView from '../views/ProfileView.vue'
import messagesView from '../views/MessagesView.vue'
import NotificationsView from '../views/NotificationsView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: FeedView
  },
  {
    path: '/feed',
    name: 'feed',
    component: FeedView
  },
  {
    path: '/messages',
    name: 'messages',
    component: messagesView
  },
  {
    path: '/feed/:stid',
    name: 'viewStory',
    component: StoriesView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: NotificationsView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
